:root {
    --primary-color: #b979f9;
    --inactive-color: #181c307a;

    --neutral-white-color: white;
    --neutral-almost-white-color: #fcfcfc;
    --neutral-stroke-color: #e6e6e6;
    --neutral-icons-color: #a9a9a9;
    --neutral-body-text-color: #636363;
    --neutral-almost-black-color: #0d0d0d;

    --accents-purple-color: #8280ff;
    --accents-trans-purple-color: #8280ff29;
    --accents-blue-color: #6c9eff;
    --accents-trans-blue-color: #6c9eff29;
    --accents-pink-color: #ea6cff;
    --accents-trans-pink-color: #ea6cff29;
    --accents-orange-color: #ff8959;
    --accents-trans-orange-color: #ff895929;

    --other-dark-blue-color: #3b4163;
    --other-trans-blue-color: #6c9eff29;
    --other-purple-stroke-color: #ceceea;
    --other-red-error-color: #ff3e3e;

    --tags-green-color: #03a500;
    --tags-trans-green-color: #03a50029;
    --tags-blue-color: #0069c0;
    --tags-trans-blue-color: #0069c029;

    --gradients-main-color: linear-gradient(to top right, #009aff 0%, #7100ff 25%, #e200c0 50%, #fa4800 100%);
    --gradients-purple-pink-color: linear-gradient(to top right, #7100ff 0%, #e200c0 100%);
    --gradients-blue-purple-pink-color: linear-gradient(to top right, #009aff 0%, #7100ff 45%, #e200c0 100%);
    --gradients-pink-orange-color: linear-gradient(to top right, #e200c0 0%, #fa4800 100%);
    --gradients-blue-purple-color: linear-gradient(to top right, #009aff 0%, #5134ff 40%, #7100ff 100%);

    --button-main-color: linear-gradient(to right, #009aff 0%, #4e55ff 21%, #7100ff 50%, #e200c0 100%);
    --button-secondary-color: linear-gradient(to right, #7100ff 0%, #e200c0 100%);
    --button-hover1-color: #6460ff;
    --button-hover2-color: linear-gradient(to right, #009aff 0%, #4e55ff 50%, #7100ff 75%, #e200c0 100%);
    --button-hover3-color: linear-gradient(to right, #7100ff 50%, #e200c0 100%);

    --light-shadow: 0px 4px 24px 0px #9a9a9a14;
    --medium-shadow: 4px 4px 24px 0px #9a9a9a3d;
    --button-shadow: 0px 4px 16px 0px #00000014;
}

.login-container {
    display: flex;
    justify-content: center;
    margin-top: 96px;
    height: 100vh;
}

.login-box {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 356px;
    align-items: start;
}

.login-title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #181c30;
    line-height: 20px;
    margin-bottom: 20px;
}

.login-component {
    text-align: left;
    display: flex;
    gap: 1.2vh;
    flex-direction: column;
    width: 100%;
}

.login-input {
    padding: 1.5vh 1.6vh;
    border-radius: 8px;
    border: 1px solid var(--neutral-stroke-color);
    box-sizing: border-box;
    transition: border-color 250ms;
}

.visibility-button {
    position: absolute;
    right: 1.5vh;
    top: 50%;
    transform: translateY(-50%);
    width: 2vh;
    cursor: pointer;
    user-select: none;
    color: #616161;
}

.visibility-button svg {
    width: 100%;
    height: auto;
    display: block;
}

.visibility-button:hover {
    color: #b979f9;
}

.visibility-button.active svg {
    transform: rotate(180deg);
}

.login-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-label {
    color: var(--neutral-almost-black-color);
}

.login-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.login-field {
    width: 100%;
    margin-bottom: 10px;
}

.login-button {
    width: 100%;
}

.gradient-text {
    background: linear-gradient(-106deg, #311e49 0%, #ac3cf3 50%, #ac3cf3 75%, #311e49 100%);
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent to reveal the gradient */
}

.dutify-button {
    z-index: 1;
    padding: calc(6px + 0.6vh) calc(12px + 0.05vh);
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.25s;
    outline: none;
    background-color: var(--accents-purple-color);
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.dutify-button:hover {
    background-color: #a0a0ff;
}

.dutify-button:active {
    background-color: var(--accents-purple-color);
}

.login-button {
    position: relative;
    display: inline-block;
    border-radius: 8px;
    padding: 1.6vh;
    overflow: hidden;
}

.login-button::before,
.login-button::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(25deg, #009aff 0%, #4e55ff 21%, #7100ff 50%, #e200c0 100%);
    z-index: -1;
}

.login-button::after {
    opacity: 0;
    background-image: linear-gradient(25deg, #009aff 0%, #4e55ff 50%, #7100ff 75%, #e200c0 100%);
    transition: opacity 0.3s ease-in-out;
}

.login-button:hover::after {
    opacity: 1;
}

.dutify-link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--accents-purple-color);
}

.dutify-link:hover {
    color: #a1a1a1;
}

.check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    color: #181c30;
    user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.check-container span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    border: 1px solid var(--accents-purple-color);
    border-radius: 4px;
    margin: 0;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ span {
    background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ span {
    background-color: var(--accents-purple-color);
    -color: var(--accents-purple-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
span:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ span:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container span:after {
    position: absolute;
    left: 6px;
    top: 1px;
    border-radius: 2px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.font-regular-big {
    font-family: 'Mona Sans';
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 2%;
}

.font-semibold-hyperlink-big {
    font-family: 'Mona Sans';
    font-weight: 600;
    font-size: calc(12px + 0.4vh);
    line-height: 110%;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
    }
}

.shake {
    animation: shake 0.5s;
}

.error-alert {
    margin-top: 16px;
    width: 100%;
}
