.call-link-container {
    margin-right: 6vh;
    margin-left: 6vh;
    margin-bottom: 1.5vh;
    border-radius: 12px;
    border: 1px solid purple;
    background-color: #d6bbec;
}

.call-link-inner {
    text-align: center;
    margin-bottom: 1.5vh;
    margin-top: 1.5vh;
}

.call-link-copy-image {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.call-link-copy-image:hover {
    background-color: #fcecd1;
}

.call-link-copy-image:hover:active {
    background-color: #bea06d;
}