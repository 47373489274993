.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.space-between {
    justify-content: space-between;
}

.padding-left-1vh {
    padding-left: 1vh;
}

.margin-right-1vh {
    margin-right: 1vh;
}

.tilte{
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
