@font-face {
  font-family: Mona Sans;
  src: url(./styles/font/Mona-Sans.ttf);
}
* {
  font-family: Mona Sans;
}

.ant-select > .ant-select-selector:hover {
  border: 1px solid rgba(185, 121, 249, 1) !important;
}

.MuiTabs-indicator {
  display: none;
}