.integration-container {
    display: flex;
    justify-content: center;
    margin-top: 96px;
}

.integration-box {
    position: relative;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
}

.zoom-container {
    display: flex;
    justify-content: center;
}

.zoom-box {
    position: relative;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.ant-select-selector {
    border-radius: 14px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    height: 48px !important;
    padding-top: 5px !important;
}

.ant-select-open > .ant-select-selector {
    border: 1px solid rgba(185, 121, 249, 1) !important;
    
}
